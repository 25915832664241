import React from "react"
import { Link } from 'gatsby'

const Item = () => {
  return (
    <React.Fragment>
      <div className="project row"
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-easing="easeInQuart"
        >
        <div className="project__info">
        <div class="project__title">
          <h2>Subway</h2>
          <small>Website</small>
        </div>
        <p className="project__paragraph">I define strategies & communications that you will need to transform your business experiences and improve people’s lives. By digging into business problems, I analyse, define and solve them with the help of creative design.</p>
        <Link className="project__btn">Visit the website</Link>
        <Link className="project__btn--case">View case</Link>
        </div>
        <img className="project__image" src="https://images.unsplash.com/photo-1591008574391-6cf6edb44938?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80" alt="blu"/>
      </div>
    </React.Fragment>
  )
}

export default Item
