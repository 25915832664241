import { Link } from "gatsby"
import React from "react"

import Item from "./Item"

const Work = () => (
  <section class="work top-lg">
    <div className="container">
      <div
        className="row"
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-easing="easeInQuart"
      >
        <h3>LATEST WORK</h3>
        <Link to="/work" className="secondary-btn">
          ALL CASES
        </Link>
      </div>
      <div className="projects column">
        <Item />
      </div>
    </div>
  </section>
)

export default Work
