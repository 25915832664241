import React from "react"

const SocialIcons = () => (
  <div>
    <ul className="social">
      <li
        className="social__item"
        data-sal="slide-right"
        data-sal-delay="200"
        data-sal-easing="easeInQuart"
      >
        <a
          href="https://github.com/fredrikjohanssonnn"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="23.408"
            viewBox="0 0 24 23.408"
          >
            <path
              d="M12,0A12,12,0,0,0,8.207,23.387c.6.111.793-.261.793-.577V20.576C5.662,21.3,4.967,19.16,4.967,19.16A3.178,3.178,0,0,0,3.634,17.4c-1.089-.745.083-.729.083-.729a2.519,2.519,0,0,1,1.839,1.237,2.554,2.554,0,0,0,3.492,1,2.546,2.546,0,0,1,.762-1.6C7.145,17,4.343,15.971,4.343,11.374A4.644,4.644,0,0,1,5.579,8.153,4.316,4.316,0,0,1,5.7,4.977S6.7,4.655,9,6.207a11.374,11.374,0,0,1,6.009,0c2.291-1.552,3.3-1.23,3.3-1.23a4.312,4.312,0,0,1,.118,3.176,4.632,4.632,0,0,1,1.235,3.221c0,4.609-2.807,5.624-5.479,5.921A2.868,2.868,0,0,1,15,19.517V22.81c0,.319.192.694.8.576A12,12,0,0,0,12,0Z"
              fill="#1b1e36"
            />
          </svg>
        </a>
      </li>
      <li
        className="social__item"
        data-sal="slide-right"
        data-sal-delay="400"
        data-sal-easing="easeInQuart"
      >
        <a
          href="https://www.linkedin.com/in/fredrik-johansson-50494a127/"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path
              d="M19,0H5A5,5,0,0,0,0,5V19a5,5,0,0,0,5,5H19a5,5,0,0,0,5-5V5A5,5,0,0,0,19,0ZM8,19H5V8H8ZM6.5,6.732A1.764,1.764,0,1,1,8.25,4.968,1.758,1.758,0,0,1,6.5,6.732ZM20,19H17V13.4c0-3.368-4-3.113-4,0V19H10V8h3V9.765c1.4-2.586,7-2.777,7,2.476Z"
              fill="#1b1e36"
            />
          </svg>
        </a>
      </li>
      <li
        className="social__item"
        data-sal="slide-right"
        data-sal-delay="600"
        data-sal-easing="easeInQuart"
      >
        <a
          href="https://twitter.com/fjohanssondev"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="19.504"
            viewBox="0 0 24 19.504"
          >
            <path
              d="M24,4.557a9.831,9.831,0,0,1-2.828.775,4.932,4.932,0,0,0,2.165-2.724A9.864,9.864,0,0,1,20.21,3.8a4.927,4.927,0,0,0-8.391,4.49A13.978,13.978,0,0,1,1.671,3.149,4.93,4.93,0,0,0,3.194,9.723,4.9,4.9,0,0,1,.965,9.107,4.927,4.927,0,0,0,4.914,14a4.935,4.935,0,0,1-2.224.084A4.928,4.928,0,0,0,7.29,17.5,9.9,9.9,0,0,1,0,19.54a13.939,13.939,0,0,0,7.548,2.212A13.925,13.925,0,0,0,21.543,7.106,10.025,10.025,0,0,0,24,4.557Z"
              transform="translate(0 -2.248)"
              fill="#1b1e36"
            />
          </svg>
        </a>
      </li>
    </ul>
  </div>
)

export default SocialIcons
