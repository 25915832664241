import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/Button"
import Work from "../components/Work"
import SocialIcons from "../components/SocialIcons"

const Home = () => {

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "profile.jpg"}) {
        childImageSharp {
          fixed(width: 500, height: 700) {
            ...GatsbyImageSharpFixed
        }
      }
    }
  }
  `)

  return (

  <Layout>
    <SEO title="Home" />
    <section className="intro top-md">
      <div className="container">
        <div className="flex" style={{justifyContent: "space-between", width: "100%"}}>
          <div className="intro__text top-md">
            <h1
              className="intro__heading"
              data-sal="slide-up"
              data-sal-delay="600"
              data-sal-easing="easeInQuart"
            >
              Frontend Developer, building websites and web applications.
            </h1>
            <p
              class="intro__paragraph"
              data-sal="slide-up"
              data-sal-delay="800"
              data-sal-easing="easeInQuart"
            >
              I define strategies & communications that you will need to
              transform your business experiences and improve people’s lives. By
              digging into business problems, I analyse, define and solve them
              with the help of creative design.
            </p>
            <Button
              text="Learn more &rarr;"
              url="/about"
              style="learn-btn"
              data-sal="slide-left"
              data-sal-delay="1200"
              data-sal-easing="ease-out-back"
            />
            <SocialIcons />
          </div>
          <Img className="intro__image" fixed={data.file.childImageSharp.fixed} alt="Image of me, walking on a fallen tree" />
        </div>
      </div>
    </section>
    <Work />
  </Layout>
  )
}

export default Home
